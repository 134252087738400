<template>
  <div class="header-nav">
    <nav>
      <ul>
        <li class="icon-link icon-link--menu">
          <a href="/" v-on:click="toggleMenu">
            <span class="close-svg" v-if="menuOpen == 'nav'">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.8 50.2">
                <title>Close Menu</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_2-2" data-name="Layer 2">
                    <g>
                      <line x1="2.6" y1="47.6" x2="43.2" y2="2.6" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.24"/>
                      <line x1="43.2" y1="47.6" x2="2.6" y2="2.6" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.24"/>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span v-else>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.6 16.9">
                <title>Open Menu</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <g>
                      <line x1="1" y1="1" x2="23.6" y2="1" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.06"></line>
                      <line x1="1" y1="8.4" x2="23.6" y2="8.4" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.06"></line>
                      <line x1="1" y1="15.9" x2="23.6" y2="15.9" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.06"></line>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </a>
        </li>
        <li v-if="!mobileScreen" class="icon-link icon-link--callmenu">
          <a href="/" v-on:click="toggleCallMenu">
            <span class="close-svg" v-if="menuOpen == 'tel'">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.8 50.2">
                <title>Close Menu</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_2-2" data-name="Layer 2">
                    <g>
                      <line x1="2.6" y1="47.6" x2="43.2" y2="2.6" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.24"/>
                      <line x1="43.2" y1="47.6" x2="2.6" y2="2.6" fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="5.24"/>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
            <span v-else>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.6 25.6">
                <title>Contact Us Menu</title>
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M17.2,2,10.1,7.9H10L2.9,13.9c-6.7,5.6.3,11.7.3,11.7l6.9-5.7a8.5,8.5,0,0,0-3.4-4.7l5.5-4.6h.1l5.5-4.7a8.5,8.5,0,0,0,4,4.2l6.8-5.7S23.9-3.6,17.2,2Z" />
                  </g>
                </g>
              </svg>
            </span>
          </a>
        </li>
        <li class="icon-link icon-link--centre">
          <a href="https://www.instagram.com/carrarinoltd/?hl=en" target="_blank">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.3 121.1">
              <title>Carrarino Instagram</title>
              <path d="M75,17.2h32.7s27.7-1,27.7,27V55.9H14V43.6S13,17.2,38.4,17.2C38.4,17.2,75,16.9,75,17.2Z" transform="translate(-14 -17.1)" />
              <path d="M101.2,66.2h34v44.7s-1.7,27.3-28.3,27.3H42s-27.8,0-27.8-27.6V66.2S100.9,65.9,101.2,66.2Z" transform="translate(-14 -17.1)" />
              <circle cx="60.7" cy="63.5" r="29.4" fill="#333f49" />
              <circle cx="60.7" cy="62.5" r="12.3" />
              <rect x="85" y="15.1" width="17.7" height="17.67" rx="5.7" fill="#333f49" />
            </svg>
          </a>
        </li>
        <li class="icon-link icon-link--right">
          <a href="https://www.linkedin.com/company/carrarino-ltd/about/" target="_blank">
            <svg id="Layer_3" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132 132">
              <title>Carrarino LinkedIn</title>
              <ellipse cx="16.3" cy="15.2" rx="16.3" ry="15.2" />
              <rect x="1.3" y="34.6" width="30" height="97.41" />
              <path d="M155,119.6l-1.9-13.7H126.7l1,27.3v69.4h30.2v-58s5.5-22.4,25-14c0,0,6.4,3.3,6.4,14.4v58h30.6V141.4s1.3-30.6-26.7-36.8C193.2,104.6,168.3,98.4,155,119.6Z" transform="translate(-88 -71.3)" />
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'navMenu',
  props: {
    mobileScreen: Boolean,
    menuOpen: String
  },
  methods: {
    toggleMenu (e) {
      e.preventDefault()
      this.$emit('toggleMenu')
    },
    toggleCallMenu (e) {
      e.preventDefault()
      this.$emit('toggleCallMenu')
    }
  }
}
</script>

<style scoped lang="scss">
  @use "../base.scss" as *;

  .header-nav {
    width: 100%;
    max-width: 80px;
    margin: 0 0 0 auto;

    @include sml {
      max-width: 100px;
    }

    @include lrg {
      margin: 0 auto;
      max-width: 210px;
    }

    nav {
      ul {
        @include lrg {
          @include flex;
          @include flex-justify-between;
          @include flex-align-center;
          margin: 20px 0 0;
        }

        li.icon-link {
          width: 15%;
          display: inline-block;
          vertical-align: middle;

          @include sml {
            width: 20%;
          }

          @include lrg {
            width: 12%;
          }

          &--menu {
            width: 30%;
            margin-right: 22%;

            @include sml {
              width: 30%;
              margin-right: 22%;
            }

            @include lrg {
              width: 12%;
              margin-right: 0;
            }
          }

          &--centre {
            margin-right: 15%;

            @include sml {
              margin-right: 8%;
            }

            @include lrg {
              margin-right: 0;
            }
          }

          &--right {

          }

          a {
            display: block;

            .close-svg {
              width: 63%;
              display: block;
            }

            svg {
              stroke: $color-lightblue;
              fill: $color-lightblue;
            }
            &--menu {
              &closed {
              }
              &open {
              }
            }

            &:hover {
              svg {
                stroke: #fff;
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }

</style>
